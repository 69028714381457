import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { OrganizationListItem } from '@mp/kernel/organizations/domain';
import { PageResponse } from '@mp/shared/data-access';

export const OrganizationsActions = createActionGroup({
  source: 'Kernel - Organizations',
  events: {
    fetchOrganizations: props<{ searchTerm?: string }>(),
    fetchOrganizationsSuccess: props<{ organizationsPageResponse: PageResponse<OrganizationListItem> }>(),
    fetchOrganizationsError: emptyProps(),

    upsertOrganizationToList: props<{ organization: OrganizationListItem }>(),
  },
});
