import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';

import { OrganizationListItem } from '@mp/kernel/organizations/domain';
import { NotificationService, PageResponse } from '@mp/shared/data-access';

import { OrganizationsService } from '../services';

import { OrganizationsActions } from './organizations.actions';

@Injectable()
export class OrganizationsEffects {
  fetchOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.fetchOrganizations),
      fetch({
        run: ({ searchTerm }) => {
          return this.organizationsService
            .fetchOrganizations(searchTerm)
            .pipe(
              map((organizationsPageResponse: PageResponse<OrganizationListItem>) =>
                OrganizationsActions.fetchOrganizationsSuccess({ organizationsPageResponse }),
              ),
            );
        },
        onError: () => {
          this.notificationService.toastDanger('Fehler beim Laden der Organisationen.');
          return OrganizationsActions.fetchOrganizationsError();
        },
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly organizationsService: OrganizationsService,
    private readonly notificationService: NotificationService,
  ) {}
}
